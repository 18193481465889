import React from "react"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

const ContactPage = (props) => {
	const langKey = props.pageContext.langKey;
	return (
		<Layout lang={langKey} headerStyle="darkHeader">
			<Seo title="Contact Us" url="/en/contact" lang={langKey} />
			<ContactFormSection lang={langKey} />
			<ContactInfoSection lang={langKey} />
		</Layout>
	)
}

export default ContactPage;
